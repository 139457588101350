

export default [
  {
    title: "First Slide",
    description: "This is the first slider Image of our carousel",
    urls: 'https://sellmydevice.s3.ca-central-1.amazonaws.com/Carousel/Time+Out!+(1).jpg',
    path:"/Macbooks"
  },
  {
    title: "Second Slide",
    description: "This is the second slider Image of our carousel",
    urls:"https://sellmydevice.s3.ca-central-1.amazonaws.com/Carousel/Sell+your+iPhone.jpg",
  },
  {
    title: "Third Slide",
    description: "This is the Third slider Image of our carousel",
    urls: "https://sellmydevice.s3.ca-central-1.amazonaws.com/Carousel/2.jpg",
  },
  {
    title: "Fourth Slide",
    description: "This is the Fourth slider Image of our carousel",
    urls:"https://cashmydevice-frontend.s3.us-west-1.amazonaws.com/image-banner/macbook.jpg",
  },
  {
    title: "Fifth Slide",
    description: "This is the Fifth slider Image of our carousel",
    urls: "https://cashmydevice-frontend.s3.us-west-1.amazonaws.com/image-banner/iwatch.jpeg",
  },
  
];
