import React, { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import { connect } from 'react-redux';
import { userPaymentInfo } from "../../../services/addressService";

import * as actions from '../../../store/actions/cart';
import Stepper from '../../Stepper/index';
import Button from 'react-bootstrap/Button';
import { isEmpty } from '../../../validation';
import { phoneRegex } from "../../../validation/validation";




const PaymentPage = (props) => {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState()
    const [formEmpty, setFormEmpty] = useState(false)
    const fromRef = useRef()
    const previousPage = () => {
        props.history.push("/cart");
        

    }
   
   
    const defaultFormValue={ name:'', useName:'', phone:'', email:'',  method:'' }
    const [formValue, setFormValue]= useState(defaultFormValue);

    

    const handleSubmit = (value) => {
        if (selectedPaymentMethod) {
            value.method = selectedPaymentMethod
            
            if (props.token) {
                props.addPayment(value)
                
                window.sessionStorage.setItem("key", JSON.stringify(value));
                
                const parsedData=JSON.parse(window.sessionStorage.getItem("key"));
                
                setFormValue(parsedData)
                
                
            } else {
                props.addPayment(value)
                window.sessionStorage.setItem("key", JSON.stringify(value));
                
                const parsedData=JSON.parse(window.sessionStorage.getItem("key"));
                
                setFormValue(parsedData)
            }
        }
        props.history.push(window.location.href="/cart/address");
    };
    
    const paymentSchema = Yup.object().shape({
        name: Yup.string()
            .required('Please enter name'),
        useName: Yup.string()
            .required('Please enter user name'),
        phone: Yup.string()
            .matches(phoneRegex, "Please enter valid contact number")
            .required('Please enter phone number'),
        email: Yup.string()
            .email('Please enter valid email!')
            .required('Please enter email'),
    });

    const paymentCashappSchema = Yup.object().shape({
        name: Yup.string()
            .required('Please enter name'),
        cashTag: Yup.string()
            .required('Please enter last name'),
        phone: Yup.string()
            .matches(phoneRegex, "Please enter valid contact number")
            .required('Please enter phone number'),
        email: Yup.string()
            .email('Please enter valid email!')
            .required('Please enter email'),
    });

    const paymentZelleSchema = Yup.object().shape({
        name: Yup.string()
            .required('Please enter email'),
        phone: Yup.string()
            .matches(phoneRegex, "Please enter valid contact number")
            .required('Please enter phone number'),
        email: Yup.string()
            .email('Please enter valid email!')
            .required('Please enter email'),
    });

    useEffect(() => {
        // if (isEmpty(props.items)) {
        //     props.history.push("/cart");
        // }
    });
    
   
    
    const nextButton = () => {
        if (fromRef.current) {
            fromRef.current.handleSubmit()
        }else{
            setFormEmpty(true)
        }
    }
    
    

    return (
        <div>
            <div>
                {
                    props.totalPayment >= 1 &&
                    <Stepper steps={[{ title: "Devices" }, { title: "Payment" }, { title: "Address" }, { title: "Checkout" }]} activeStep={1} />
                }
                {
                    props.totalPayment === 0 &&
                    <Stepper steps={[{ title: "Devices" }, { title: "Address" }, { title: "Checkout" }]} activeStep={0} />
                }
            </div>
            <div className="mt-5 ml-5">
                <h3>How would you like to get paid: </h3>
                <p className='text-danger'>Please note: Cash payment option is for the local customer only willing to meet at public places in following cities of GTA.</p>
                <ul>
                    <li>Ajax</li>
                    <li>Brampton</li>
                    <li>Etobicoke</li>
                    <li>Markham</li>
                    <li>Mississauga</li>
                    <li>Oshawa</li>
                    <li>Pickering</li>
                    <li>Richmond Hill</li>
                    <li>Toronto</li>
                    <li>Vaghaun</li>
                    <li>Whitby</li>
                    
                </ul>
                <p>If you can't meet in person at above mentioned cities, Please select Check or E-transfer as payment method</p>
                <ul></ul>
            </div>
            <div className="mt-4 ml-5">
                <h4>Payment method: </h4>
            </div>
         {formEmpty &&<span className="text-danger">Please select the payment method</span>}
            <div className="row ml-4">
                <div className="col-md-12 mt-3 mr-3 ">
                    <div className="col-md-4 p-4 bg-aliceblue rounded">
                    
                    <input className="float-left" onClick={() => setSelectedPaymentMethod('Cash')} type="radio" value="Cash" name="method" /> 
                        <label className='ml-5'>Cash  <span className="text-danger">(For local customer only located in GTA)</span></label>
                        
                        {
                            selectedPaymentMethod === 'Cash' &&
                            <Formik
                               
                                initialValues={formValue}
                                
                                onSubmit={handleSubmit}
                                validationSchema={paymentSchema}
                                innerRef={fromRef}
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <div className="col-md-12 pl-0 mt-2">
                                        <span className="text-danger">*</span>
                                            <label>
                                                First Name:
                                               </label>
                                            <div>
                                                <Field className="form-control" type="text" name="name"  />
                                                <ErrorMessage name="name" className="text-danger" component="div" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 pl-0">
                                        <span className="text-danger">*</span>
                                            <label>
                                                Last Name:
                                               </label>
                                            <div>
                                                <Field className="form-control" type="text" name="useName" />
                                                <ErrorMessage name="useName" className="text-danger" component="div" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 pl-0">
                                        <span className="text-danger">*</span>
                                            <label>
                                                Phone:
                                               </label>
                                            <div>
                                                <Field className="form-control" type="text" name="phone" placeholder="Asociate will contact you on this for meeting"/>
                                                <ErrorMessage name="phone" className="text-danger" component="div" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 pl-0">
                                        <span className="text-danger">*</span>
                                            <label>
                                                Email :
                                                </label>
                                            <div>
                                                <Field className="form-control" type="text" name="email" />
                                                <ErrorMessage name="email" className="text-danger" component="div" />
                                            </div>
                                        </div>
                                        
                                    </Form>
                                )}
                            </Formik>
                        }
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="col-md-4 p-3 bg-aliceblue rounded">
                    <input className="float-left" onClick={() => setSelectedPaymentMethod('PayPal')} type="radio" value="PayPal" name="method" />
                        <label  className='ml-5'>PayPal</label>
                       
                        {
                            selectedPaymentMethod === 'PayPal' && <Formik
                                initialValues={{ name: '', useName: '', phone: '', email: '' }}
                                onSubmit={handleSubmit}
                                validationSchema={paymentSchema}
                                innerRef={fromRef}
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <div className="col-md-12 pl-0 mt-2">
                                            <label>
                                                First Name:
                                                </label>
                                            <div>
                                                <Field className="form-control" type="text" name="name" />
                                                <ErrorMessage name="name" className="text-danger" component="div" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 pl-0">
                                            <label>
                                                Last Name
                                                </label>
                                            <div>
                                                <Field className="form-control" type="text" name="useName" />
                                                <ErrorMessage name="useName" className="text-danger" component="div" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 pl-0">
                                        <span className="text-danger">*</span>
                                            <label>
                                                Phone (If you already have PayPal account provide the same Phone Number registered with PayPal):
                                                </label>
                                            <div>
                                                <Field className="form-control" type="text" name="phone" />
                                                <ErrorMessage name="phone" className="text-danger" component="div" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 pl-0">
                                        <span className="text-danger">*</span>
                                            <label>
                                              Email (If you already have PayPal account provide the email registered with PayPal) :
                                               </label>
                                            <div>
                                                <Field className="form-control" type="text" name="email" />
                                                <ErrorMessage name="email" className="text-danger" component="div" />
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        }
                    </div>
                </div>

                <div className="col-md-12 mt-3">
                    <div className="col-md-4 p-3 bg-aliceblue rounded">
                    <input className="float-left" onClick={() => setSelectedPaymentMethod('E-transfer')} type="radio" value="E-transfer" name="method" />
                        <label className='ml-5'>E-transfer(Interac)</label>
                       
                        {
                            selectedPaymentMethod === 'E-transfer' && <Formik
                                initialValues={{ name: '', cashTag: '', phone: '', email: '' }}
                                onSubmit={handleSubmit}
                                validationSchema={paymentCashappSchema}
                                innerRef={fromRef}
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <div className="col-md-12 pl-0 mt-2">
                                            <label>
                                                Name:
                                                </label>
                                            <div>
                                                <Field className="form-control" type="text" name="name" />
                                                <ErrorMessage name="name" className="text-danger" component="div" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 pl-0">
                                            <label>
                                                Interac Email:
                                             </label>
                                            <div>
                                                <Field className="form-control" type="text" name="cashTag" />
                                                <ErrorMessage name="cashTag" className="text-danger" component="div" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 pl-0">
                                            <label>
                                                Phone:
                                                </label>
                                            <div>
                                                <Field className="form-control" type="text" name="phone" />
                                                <ErrorMessage name="phone" className="text-danger" component="div" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 pl-0">
                                            <label>
                                            Confirm  Interac Email :
                                               </label>
                                            <div>
                                                <Field className="form-control" type="text" name="email" />
                                                <ErrorMessage name="email" className="text-danger" component="div" />
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        }

                    </div>
                </div>

                
            </div>
            <div className="row mt-4 ml-1">
                <div className="col-md-6 mt-4 d-flex">
                    <Button className="w-100" onClick={previousPage} variant="secondary ml-4">Previous</Button>
                    <Button className="w-100" onClick={nextButton} variant="secondary ml-4">Next</Button>
                </div>
            </div>

        </div>)
}

const mapStateToProps = state => {
    return {
        method: state.cart.payment.method,
        totalPayment: state.cart.totalPayment,
        token: state.auth.token
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addPayment: (value) => dispatch(actions.updateCartPayment(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);