import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const deviceNav = (props) => {

    const linkTo = (link) => {
        props.history.push(link)
    }

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                   

                    <NavDropdown title="iPhones" id="collasible-nav-dropdown" >
                    <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 15 Pro Max")}>iPhone 15 Pro Max</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 15 Pro")}>iPhone 15 Pro</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 15 Plus")}>iPhone 15 Plus </NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 15")}>iPhone 15 </NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 14 Pro Max")}>iPhone 14 Pro Max</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 14 Pro")}>iPhone 14 Pro</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 14 Plus")}>iPhone 14 Plus</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 14")}>iPhone 14 </NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone SE 3rd")}>iPhone SE(3rd)</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 13 Pro Max")}>iPhone 13 Pro Max</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 13 Pro")}>iPhone 13 Pro</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 13")}>iPhone 13</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 13 Mini")}>iPhone 13 Mini</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 12 Pro Max")}>iPhone 12 Pro Max</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 12 Pro")}>iPhone 12 Pro</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 12")}>iPhone 12</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPphone 12 Mini")}>iPhone 12 Mini</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone SE 2nd")}>iPhone SE(2nd)</NavDropdown.Item>
                    
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 11 Pro Max")}>iPhone 11 Pro Max</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 11 Pro")}>iPhone 11 Pro</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 11")}>iPhone 11</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone XS Max")}>iPhone XS Max</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone XS")}>iPhone XS</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone XR")}>iPhone XR</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone X")}>iPhone X</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 8 Plus")}>iPhone 8 Plus</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 8 Plus")}>iPhone 8 </NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 7 Plus")}>iPhone 7 plus</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 7")}>iPhone 7</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 7")}>iPhone SE(1st)</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPhone/iPhone 6S Plus")}>iPhone 6s Plus</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="MacBooks" id="collasible-nav-dropdown">
                        <NavDropdown.Item onClick={e => linkTo("/Macbook/Macbook")}>Macbook</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/Macbook/Macbook Air")}>Macbook Air</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/Macbook/Macbook Pro")}>Macbook Pro</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="iPads" id="collasible-nav-dropdown">
                        <NavDropdown.Item onClick={e => linkTo("/iPad/iPadPro")}>iPad Pro</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPad/iPadMini")}>iPad Mini</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPad/ipadAir")}>iPad Air</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iPad/iPad")}>iPad</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Apple iWatch" id="collasible-nav-dropdown">
                        <NavDropdown.Item onClick={e => linkTo("/iWatch/Apple Watch (Series 5)")}>iWatch Series 5</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iWatch/Apple Watch (Series 6)")}>iWatch Series 6</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iWatch/Apple Watch (Series 7)")}>iWatch Series 7</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iWatch/Apple Watch (Series 8)")}>iWatch Series 8</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iWatch/Apple Watch (SE) 2nd Gen.")}>iWatch Series SE 2nd Gen</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iWatch/Apple Watch Ultra")}>iWatch Ultra</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iWatch/Apple Watch (Series 9)")}>iWatch Series 9</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/iWatch/Apple Watch Ultra 2")}>iWatch Ultra 2</NavDropdown.Item>
                    </NavDropdown>

                 { /*  <NavDropdown title="Android Phones" id="collasible-nav-dropdown">
                        <NavDropdown.Item onClick={e => linkTo("/AndroidPhone")}>Samsung</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/google")}>Google</NavDropdown.Item>
    </NavDropdown>*/}
                    <NavDropdown title="AirPods" id="collasible-nav-dropdown">
                        <NavDropdown.Item onClick={e => linkTo("/Airpods/AirPods Pro(2nd Gen)")}>AirPods Pro(2nd Gen)</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/Airpods/AirPods Pro(1st Gen)")}>AirPods Pro(1st Gen)</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/Airpods/AirPods (3rd Gen)")}>AirPods (3rd Gen)</NavDropdown.Item>
                        <NavDropdown.Item onClick={e => linkTo("/Airpods/AirPods Max")}>AirPods Max</NavDropdown.Item>
                    </NavDropdown>
                    
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}


export default deviceNav