import React from 'react'
import Card from '../../Card/Card'
import HowItWorks from '../../HowItWorks/HowItWorks'

function WhySellToUs(props) {
  return (
    <>
    <div>
      <Card {...props} />
    </div>
    <div>
      
      <HowItWorks/>
    </div></>
    
  )
}

export default WhySellToUs
