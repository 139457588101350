import React, { Component } from 'react'

class CheckOutSuccessPage extends Component {
    render() {
        return (
            <div className="text-center">
                <p className="text-color pt-3 pb-3">
                    <h4>
                        Congratulation!
                    </h4>
                </p>
                <p>
                    We have received your order!<br/>
                    Please check your email for the instructions on how to ship your devices to us and for shipping label<br/>
                    We thrive for the best user experience.
                    If you are meeting us in-person for local meetup in GTA,<br/>
                     please look forward to be contacted by our associate on your Phone Number,<br/>
                     provided during the Payment method step.
                    Please contact us if you have any questions at any point during the shipping process or in-general.
                </p>

                <p>
                    For further information on what to do next please <a href="/Support/CustomerSupport">click here</a>
                </p>

            </div>
        )
    }
}

export default CheckOutSuccessPage
