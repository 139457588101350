import { Field, Form, Formik, ErrorMessage } from 'formik';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { connect, useSelector } from 'react-redux';
import { checkOutData, checkOutDataAsGuest } from "../../../services/addressService";
import { rewards, userRewards } from "../../../services/myAccountService";
import * as actions from '../../../store/actions/cart';
import { isEmpty } from '../../../validation';
import Stepper from '../../Stepper/index';
import LoaderSpinner from "../../Loader/loaderSpinner";
import * as Yup from 'yup';

const CheckOutPage = (props) => {
    const cartData = useSelector(state => state.cart);
    const [total, setTotal] = useState(0)
    const [promoCode, setPromoCode] = useState(null)
    const [promoErrorMessage, setPromoErrorMessage] = useState(null)
    const [promoSuccessMessage, setPromoSuccessMessage] = useState(null)
    const [promoCodeBonus, setPromoCodeBonus] = useState(null)
    const [isLoader, setIsLoader] = useState(false)
    const [count, setCount]= useState(1)
    const [boxes, setBoxes] = useState([]);
    

    const previousPage = () => {
        props.history.push(window.location.href="/cart/address");
    }
    
    const handleSubmit = () => {
        setIsLoader(true)
        const obj = {
            firstName: props.address.firstName,
            lastName: props.address.lastName,
            email: props.paymentMethod.email,
            devices: cartData.items,
            totalPayment: total,
            paymentMethod: props.paymentMethod.method,
            payment_name: props.paymentMethod.name,
            paymentUsername: props.paymentMethod.useName,
            paymentPhone: props.paymentMethod.phone,
            paymentEmail: props.paymentMethod.email,
            phoneNumber: props.paymentMethod.phone ? props.paymentMethod.phone : '',
            addressType: props.address.addType,
            addressLine1: props.address.addressLine1,
            addressLine2: props.address.addressLine2,
            city: props.address.city,
            state: props.address.state,
            zipcode: props.address.zip
        }
        if (props.token) {
            checkOutData(obj).then(
              
                (res) => {
                    setIsLoader(false)
                window.location.href="/check-out-success";
                    props.clearCart();
                }
            ).catch(err => {
                console.log(err);
            })
        } else {
            const data = {
                firstName: props.address.firstName,
                lastName: props.address.lastName,
                email: props.paymentMethod.email,
                devices: cartData.items,
                totalPayment: total,
                paymentMethod: props.paymentMethod.method,
                payment_name: props.paymentMethod.name,
                paymentUsername: props.paymentMethod.useName,
                paymentPhone: props.paymentMethod.phone,
                paymentEmail: props.paymentMethod.email,
                phoneNumber: props.paymentMethod.phone ? props.paymentMethod.phone : '',
                addressType: props.address.addType,
                addressLine1: props.address.addressLine1,
                addressLine2: props.address.addressLine2,
                city: props.address.city,
                state: props.address.state,
                zipcode: props.address.zip
            }
            checkOutDataAsGuest(data).then(
                (res) => {
                   window.location.href="/check-out-success";
                    props.clearCart();
                }
            ).catch(err => {
                console.log(err);
            })
        }
    };
    const validation=
        Yup.object().shape({
            tandc:Yup.boolean()
            .oneOf([true], "You must accept the terms and conditions")
        });
    
    
    
    function handleChange(e) {

        // Destructure the children from the parent of
        // the element that was changed (ie all the input elements)
        const { parentNode: { children } } = e.target;
    
        // Find the index of the box that was changed
        const index = [...children].indexOf(e.target);
    
        // Copy the state
        const newState = [...boxes];
    
        // Toggle the boolean at the index of
        // the `newState` array
        newState[index] = !newState[index];
    
        // Set the state with the updated array
        setBoxes(newState);
      }
    
      // `filter` the boxes that return true.
      // Return true if the length is 0 or > 1.
      function isDisabled() {
        const len = boxes.filter(box => box).length;
        return len === 0 || len > 1;
      }
    const applyPromoCode = () => {
        if (promoCode) {
            const obj = {
                promocode: promoCode
            };
            rewards(obj).then(
                (res) => {
                    if (!isEmpty(res.data.results)) {
                        const bonus = res.data.results[0].bonus.replace('$', '')
                        setPromoCodeBonus(parseInt(bonus))
                        if (isEmpty(res.data.results[0].condition)) {
                            const finalTotal = total + parseInt(bonus)
                            setTotal(finalTotal)
                            setPromoSuccessMessage(`Promo code ${promoCode} applied`)
                        } else {
                            const error = [];
                            const condition = res.data.results[0].condition
                            if (condition.includes('cartValue=>$100')) {
                                if (total > 100) { } else {
                                    error.push('Cart value should be minimum $100')
                                }
                            }
                            if (condition.includes('registerUserOnly')) {
                                if (props.token) {
                                    const userRewardsObj = {
                                        id: localStorage.getItem('UserId'),
                                        promocode: res.data.results[0].id
                                    }
                                    userRewards(userRewardsObj).then(
                                        (response) => {
                                            if (!isEmpty(response.data.results[0].promocode)) {
                                                const finalTotal = total + parseInt(bonus)
                                                setTotal(finalTotal)
                                                setPromoSuccessMessage(`Promo code ${promoCode} applied`)
                                            } else {
                                                error.push('Invalid promo code')
                                            }
                                        })
                                } else {
                                    error.push('Please login or register to avail this promo code')
                                }
                            }

                            if (!error.length) {
                                const finalTotal = total + parseInt(bonus)
                                setTotal(finalTotal)
                                setPromoSuccessMessage(`Promo code ${promoCode} applied`)
                            } else {
                                setPromoErrorMessage(error[0])
                            }

                        }
                    }
                }
            ).catch(err => {
                console.log(err);
            })
        }
    }

    useEffect(() => {
        var total = cartData && cartData.items.length &&
            cartData.items.reduce((a, { deviceOffer }) => a + deviceOffer, 0) || 0
        setTotal(total)
    }, [cartData])

    useEffect(() => {
        if (isEmpty(props.items)) {
            props.history.push("/cart");
        }
    });
    

    return (
        <div>
            <div>
                {
                    props.totalPayment >= 1 &&
                    <Stepper steps={[{ title: "Cart" }, { title: "Payment" }, { title: "Address" }, { title: "Checkout" }]} activeStep={3} />
                }
                {
                    props.totalPayment === 0 &&
                    <Stepper steps={[{ title: "Cart" }, { title: "Address" }, { title: "Checkout" }]} activeStep={2} />
                }
            </div>

            <div className="mt-4 ml-5">
                <h5> Order summary: </h5>
            </div>
            <Formik
                initialValues={{ method: props.paymentMethod.method ? props.paymentMethod.method : '',
                                tandc:false }}
                onSubmit={handleSubmit}
                validationSchema={validation}
            >
                {({ isSubmitting, values }) => (
                    <Form>
                        <div className="row mt-4 ml-1 mr-1">
                            {
                                !isEmpty(cartData.items) && cartData.items.map((item, index) => (
                                    
                                    <div className="col-md-4 mt-4 bg-aliceblue d-flex justify-content-space-between" key={index} >
                                        <div className="ml-2 mt-2 mb-2 d-left">
                                            Device: {index+1}
                                            <img width="80" src={item.imagePath} />
                                            <div className="ml-2 mt-2">
                                                <h7>
            
                                                Model:  {item.deviceModel} <br />
                                                </h7>
                                             
                                                <h7>
                                                Condition:    {item.deviceCondition === '100%' ? "Good" : item.deviceCondition === '75%' ? "Used" : item.deviceCondition === '50%' ? "Poor" : item.deviceCondition === 'Broken' ? "Faulty" : item.deviceCondition}
                                                </h7>
                                            
                                       
                                        
                                            <p>
                                                {item.deviceOffer <= 0 && 'Recycling'}
                                                {item.deviceOffer > 0 && `Offer: $${item.deviceOffer}`}
                                            </p>
                                            </div>
                                            </div>
                                            
                                    </div>
                                ))
                            }
                            {/* <div className="col-md-12 mt-4 d-flex">
                                <div>
                                    <Field className="form-control" onChange={(e) => {
                                        setPromoCode(e.target.value)
                                        setPromoErrorMessage(null)
                                        setPromoSuccessMessage(null)
                                        setTotal(total - promoCodeBonus)
                                        setPromoCodeBonus(null)
                                    }} placeholder="Enter promo code" type="text" name="promoCode" />
                                </div>
                                <div >
                                    <Button className="w-100" onClick={applyPromoCode} variant="secondary ml-4">Apply</Button>
                                </div>
                            </div> */}
                            {/* {
                                promoErrorMessage &&
                                <div className="ml-4 mt-2 text-danger">
                                    {promoErrorMessage}
                                </div>
                            }

                            {
                                promoSuccessMessage &&
                                <div className="ml-4 mt-2 text-success">
                                    {promoSuccessMessage}
                                </div>
                            } */}
                           
                            <div className="col-md-12 mt-4">
                                <h6> Name: {cartData && cartData.address.firstName}</h6>
                            </div>
                            {
                                cartData && cartData.address &&
                                <div className="col-md-12 mt-2">
                                    <h6>Address : {cartData.address.addressLine1}, {cartData.address.state}, {cartData.address.city}</h6>
                                </div>
                            }

                            {<div className="col-md-12 mt-2">
                                <h6>Payment mode : {cartData && cartData.payment.method}</h6>
                            </div> }

                            {
                                total > 0 &&
                                <div className="col-md-12">
                                    <hr />
                                    <h4 className='text-danger'>Total amount  : ${total}</h4>
                                </div>
                            }
                            </div>
                            <p>Please carefully read our<a type="link" data-toggle="modal" data-target="#exampleModalCenter"> 
                            <span className="text-danger cursor-pointer"><u> terms and conditions </u></span></a>before placing your order.</p>
                            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                                            <div class="modal-dialog modal-dialog-centered" role="document">
                                                                <div class="modal-content">
                                                                    <div class="modal-header">
                                                                        <h5 class="modal-title" id="exampleModalLongTitle">Terms and conditions to </h5>
                                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                        </button>
                                                                    </div>
                                                                  {/*Terms and condition */}  <div class="modal-body">
                                                                        <p>It is important to accurately rate the physical and functional condition of your item. These conditions are crucial to helping sell my device  meet your expectations.</p>
                                                                        <p><strong>Brand new (if all of the following is true):</strong>
                                                                            <ul>
                                                                                <li> iPhones is still in original packaging and sealed in the box which also includes all the accessories comes inside it..</li>
                                                                                <li> iPhones is untouched.</li>
                                                                                <li> iPhone's box never been opened.</li>
                                                                            </ul>
                                                                        </p>
                                                                        <p><strong>Good (if the following is true):</strong>
                                                                            <ul>
                                                                                <li> Your iPhone is in great working order with some minor cosmetic signs of wear.</li>
                                                                                <li>iPhone has few light marks of use, battery health must be above 90%.</li>
                                                                                <li> iPhone must not have any sign of water damage or stains on outer body.</li>
                                                                            </ul>
                                                                        </p>
                                                                        <p><strong>Fair (if the following is true):</strong>
                                                                
                                                                            <ul>
                                                                                <li>  Your iPhone is in  working order with several minor cosmetic signs of wear.</li>
                                                                                <li> iPhone has moderate to heavy marks of use, battery health is below 90%.</li>
                                                                                <li> iPhone must not have any sign of water damages.</li>
                                                                            </ul>
                                                            
                                                                        </p>
                                                                        <p><strong>Broken (if any of the following is true):</strong>
                                                                
                                                                            <ul>
                                                                                <li> Does not power on.</li>
                                                                                <li> Broken or cracked LCD screen or glass.</li>
                                                                                <li>  Missing buttons (power, home, ringer, volume).</li>
                                                                                <li> Broken or cracked hardware (charging ports, casing, etc.)
                                                                                Housing separation beyond the width of a nickel,
                                                                                have signs of water damages.</li>
                                                                            </ul>
                                                                        </p>
                                                                        <p>It is important to accurately rate the physical and functional condition of your item. These conditions are crucial to helping sell my device  meet your expectations.</p>
                                                                        <p><strong>Brand new (if all of the following is true):</strong>
                                                                            <ul>
                                                                                <li> iPhones is still in original packaging and sealed in the box which also includes all the accessories comes inside it..</li>
                                                                                <li> iPhones is untouched.</li>
                                                                                <li> iPhone's box never been opened.</li>
                                                                            </ul>
                                                                        </p>
                                                                        <p><strong>Good (if the following is true):</strong>
                                                                            <ul>
                                                                                <li> Your iPhone is in great working order with some minor cosmetic signs of wear.</li>
                                                                                <li>iPhone has few light marks of use, battery health must be above 90%.</li>
                                                                                <li> iPhone must not have any sign of water damage or stains on outer body.</li>
                                                                            </ul>
                                                                        </p>
                                                                        <p><strong>Fair (if the following is true):</strong>
                                                                
                                                                            <ul>
                                                                                <li>  Your iPhone is in  working order with several minor cosmetic signs of wear.</li>
                                                                                <li> iPhone has moderate to heavy marks of use, battery health is below 90%.</li>
                                                                                <li> iPhone must not have any sign of water damages.</li>
                                                                            </ul>
                                                            
                                                                        </p>
                                                                        <p><strong>Broken (if any of the following is true):</strong>
                                                                
                                                                            <ul>
                                                                                <li> Does not power on.</li>
                                                                                <li> Broken or cracked LCD screen or glass.</li>
                                                                                <li>  Missing buttons (power, home, ringer, volume).</li>
                                                                                <li> Broken or cracked hardware (charging ports, casing, etc.)
                                                                                Housing separation beyond the width of a nickel,
                                                                                have signs of water damages.</li>
                                                                            </ul>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>   
                                                        
                            <div role="group" aria-labelledby="checkbox-group">
                           
                                
                                <Field type="checkbox"name="tandc"  id="tandc"/>

                                <lable><span>   I Accept the terms and conditions.</span> </lable>
                                <ErrorMessage name="tandc" className="text-danger" component="div" />
                           
                            {/*<lable>
                                two
                                <input type="checkbox" onChange={handleChange}/>
                            </lable>
                            <label>
                                three
                            <input type="checkbox" onChange={handleChange}/>
                            </label>*/}
                            
                        </div>


                            <div className="col-md-6 mt-4 d-flex">
                                <Button className="w-100" onClick={previousPage} variant="secondary ml-4" disabled={isSubmitting}>{'Previous'}</Button>
                                <Button className="w-100" type="submit" variant="secondary ml-4" disabled={isSubmitting}>
                                    {total <= 0 && 'Recycle now'} {total > 0 && 'Sell My Devices Now'}
                                </Button>
                            </div>
                        
                    </Form>
                )}
                
            </Formik>
            {isLoader && 
            <div className="text-center">
                <LoaderSpinner></LoaderSpinner>
            </div>
            
    
        }
        
        </div >

        
       
    )
    
}

const mapStateToProps = state => {
    return {
        paymentMethod: state.cart.payment,
        items: state.cart.items,
        token: state.auth.token,
        address: state.cart.address,
        totalPayment: state.cart.totalPayment
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addPayment: (value) => dispatch(actions.updateCartPayment(value)),
        clearCart: (username, password) => dispatch(actions.clearCartItem())

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckOutPage);
